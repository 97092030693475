import { Accessibility } from '@firebolt-js/sdk';
import { updateClosedCaptions } from 'core-app/store/player';
import { useEffect } from 'react';
import { selectIsAppInitialized } from 'store/app';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setTTSEnabled } from 'core-app/store/deviceSettings/deviceSettingsSlice';
import { getDeviceTTSEnabled } from 'utils/platform';

export function useDeviceStartup() {
  const isAppInitialized = useAppSelector(selectIsAppInitialized);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isAppInitialized) return;
    const init = async () => {
      //grab initial settings from firebolt.  listeners should be added for changes.
      const ccSettings: Accessibility.ClosedCaptionsSettings =
        await Accessibility.closedCaptionsSettings();
      closedCaptionsChanged(ccSettings);

      const ttsEnabled = await getDeviceTTSEnabled();
      dispatch(setTTSEnabled(ttsEnabled));

      setupListeners();
    };

    const setupListeners = async () => {
      await Accessibility.closedCaptionsSettings(closedCaptionsChanged);
      await Accessibility.voiceGuidanceSettings(voiceGuidanceChanged);
    };

    const closedCaptionsChanged = (ccSettings: Accessibility.ClosedCaptionsSettings) => {
      if (!ccSettings) return;
      dispatch(updateClosedCaptions(ccSettings.enabled));
    };

    const voiceGuidanceChanged = (ttsSettings: Accessibility.VoiceGuidanceSettings) => {
      if (!ttsSettings) return;
      dispatch(setTTSEnabled(ttsSettings.enabled));
    };

    init();
  }, [dispatch, isAppInitialized]);
}
