import { setTTSEnabled } from 'core-app/store/deviceSettings';
import { useCallback, useEffect } from 'react';
import { setIsBackgrounded } from 'store/app';
import { useAppDispatch } from 'store/hooks';
import { getDeviceTTSEnabled } from 'utils/platform';

export function useIsAppBackgrounded() {
  const dispatch = useAppDispatch();

  const callback = useCallback(() => {
    dispatch(setIsBackgrounded(document.hidden));
    const checkTTSEnabled = async () => {
      const ttsEnabled = await getDeviceTTSEnabled();
      dispatch(setTTSEnabled(ttsEnabled));
    };
    if (!document.hidden) checkTTSEnabled();
  }, [dispatch]);

  useEffect(() => {
    document.addEventListener('visibilitychange', callback);

    return () => {
      document.removeEventListener('visibilitychange', callback);
    };
  }, [callback]);
}
